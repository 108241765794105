export default defineNuxtRouteMiddleware((to) => {
    const { useAuth } = useAuthEndpoints();
    const { session } = useAuth();
    const jwtToken = session.value?.accessToken;

    if (!jwtToken) {
        return navigateTo('/login');
    }

    const isSetupRequired = useCookie('isSetupRequired');

    // If the user is authenticated but hasn't completed the setup
    if (jwtToken && isSetupRequired.value && !to.fullPath.includes('/setup')) {
        return navigateTo('/setup-start');
    }

    return true;
});
